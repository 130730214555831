export const environment = {
  production: window['env']['production'] || false,
  apiUrl: window['env']['apiUrl'] || 'https://portal.api.rc.cloudfactory.dk/',
  dropboxApiUrl: window['env']['dropboxApiUrl'] || 'http://10.245.110.243:8002',
  logoUrl: window['env']['logoUrl'] || 'https://portal.api.rc.cloudfactory.dk/Partners/{id}/Logo',
  acronisApiUrl: window['env']['acronisApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/acronis',
  customerapiUrl: window['env']['customerapiUrl'] || 'https://portal.api.rc.cloudfactory.dk/v2/customers/',
  partnerapiUrl: window['env']['partnerapiUrl'] || 'https://portal.api.rc.cloudfactory.dk/v2/partners/',
  ESETApiUrl: window['env']['esetapiUrl'] || 'https://portal.api.rc.cloudfactory.dk/eset',
  helloSignUrl: window['env']['helloSignapiUrl'] || 'https://portal.api.rc.cloudfactory.dk/hellosign',
  dropboxApiUrlV2: window['env']['dropboxApiUrlV2'] || 'https://portal.api.rc.cloudfactory.dk/dropbox/v2',
  catalogueApiUrl: window['env']['catalogueApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/v2/catalogue/',
  notificationApiUrl: window['env']['notificationApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/notification/',
  exclaimerApiUrl: window['env']['exclaimerApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/exclaimer',
  keepItApiUrl: window['env']['keepItApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/keepit',
  billingApiUrl: window['env']['billingApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/billing',
  microsoftV2API: window['env']['microsoftV2API'] || 'https://portal.api.rc.cloudfactory.dk/v2/microsoft',
  gdapAPIUrl: window['env']['gdap'] || 'https://portal.api.rc.cloudfactory.dk/microsoft-gdap',
  activityLogApiUrl: window['env']['activityLogApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/activity-logs',
  intercomProxyUrl: window['env']['intercomProxyUrl'] || 'https://portal.api.rc.cloudfactory.dk/v1/intercomproxy',
  microsoftTransfersApiUrl: window['env']['microsoftTransfersApiUrl'] || 'https://portal.api.rc.cloudfactory.dk/v1/microsofttransfers',
  impossibleCloudUrl: window['env']['impossibleCloudUrl'] || 'https://portal.api.rc.cloudfactory.dk/impossibleCloud',

  auth: {
    authorizationParams: {
      redirect_uri: window['env']['auth_redirecturi'] || 'https://' + location.host + '/callback',
      audience: window['env']['auth_audience'] || 'https://portal.api.cloudfactory.dk',
    },
    domain: window['env']['auth_domain'] || 'auth.cloudfactory.dk',
    clientId: window['env']['auth_clientid'] || 'SYpLVEnG0syNcL5QYEvxB8MmiOPcmGwf',
  },
  EndCustomerMicrosoftRoleId: window['env']['EndCustomerMicrosoftRoleId'] || 7,
  vmConsoleUrl: window['env']['vmConsoleUrl'] || 'https://terminal.cloudfactory.dk',

  featureFlags: {
    microsoft_AlignEndDate: true,
  },
};
